body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*Etec Minds*/
.MuiTable-root{
    background-color:lightcyan;
}
.MuiTableCell-head{
    font-weight: bold !important;
    background-color: rgb(250, 250, 250);
}
.MuiTableCell-root{
    border-right: 2px solid rgb(200, 200, 200);
    border-bottom: 2px solid rgb(200, 200, 200) !important;
    padding: 0 0 0 5px !important;
}
td .MuiIconButton-root{
    padding: 8px;
}
td .MuiInputLabel-root{
    display: none;
}
.MuiTypography-root{
    padding: 0 0 10px 3px;
}